import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import { makeStyles } from '@material-ui/core/styles';
import ScrollTo from '../model/scroll_to';

export default function MyAppBar() {

  window.onresize = GetScreenWidth;
  const [screenWidth, setScreenWidth] = React.useState("big");

  React.useEffect(() => {
    GetScreenWidth();
  }, [])

  function GetScreenWidth() {
    console.log(window.innerWidth);
    if (window.innerWidth <= 800) {
      setScreenWidth("small");
    } else {
      setScreenWidth("big");
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{
        bgcolor: '#fff',
      }} position="fixed">
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '85%', margin: '0 auto' }}>
          <a href="#header"><img src="images/logo.png" width="50px" /></a>
          <div style={{ display: 'flex' }}>
            {screenWidth == "big"
              ? <div className="links">
                <a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("features")} className="link-item">Services</a>
                <a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("portfolio")} className="link-item">Portfolio</a>
                <a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("about")} className="link-item">About</a>
                <a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("faq")} className="link-item">FAQ</a>
              </div>
              : null
            }

            <a href="https://www.upwork.com/freelancers/~014216a82a164c76a0" target="_blank"><img className="external-buttons" src="images/upworklogo.jpg" width="50px" /></a>
            <a href="https://discord.gg/7EtkKabkhc" target="_blank"><img className="external-buttons" src="images/discordlogo.jpg" width="50px" /></a>

          </div>
        </div>
      </AppBar>
    </Box>
  );
}