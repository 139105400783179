import React from 'react';
import '../App.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Grid from '@mui/material/Grid';
import WebIcon from '@mui/icons-material/Web';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CodeIcon from '@mui/icons-material/Code';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ScrollEffect } from 'react-easy-scroll-effect'
import 'react-easy-scroll-effect/dist/index.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MyAppBar from '../controller/appbar';
import ScrollTo from '../model/scroll_to';
import ImageViewer from 'awesome-image-viewer'

function HomePage() {

  const [animatedText, setAnimatedText] = React.useState("");

  const virawarnImages = [
    {
        mainUrl: "images/virawarn_1.png",
        description: "",
    },
    {
        mainUrl: "images/virawarn_2.png",
        description: "",
    },
    {
        mainUrl: "images/virawarn_3.png",
        description: "",
    },
    {
        mainUrl: "images/virawarn_4.png",
        description: "",
    }
  ];

  const doordashImages = [
    {
        mainUrl: "images/doordash_1.png",
        description: "",
    },
    {
        mainUrl: "images/doordash_2.png",
        description: "",
    }
  ];

  const operationsImages = [
    {
        mainUrl: "images/operations_1.png",
        description: "",
    },
    {
        mainUrl: "images/operations_2.png",
        description: "",
    },

    {
        mainUrl: "images/operations_3.png",
        description: "",
    }
  ];

  const uptimeImages = [
    {
        mainUrl: "images/uptime_1.png",
        description: "",
    },
    {
        mainUrl: "images/uptime_2.png",
        description: "",
    },
    {
        mainUrl: "images/uptime_3.png",
        description: "",
    },
    {
        mainUrl: "images/uptime_4.png",
        description: "",
    },
    {
        mainUrl: "images/uptime_5.png",
        description: "",
    }
  ];

  function ShowVirawarnImages() {
    new ImageViewer({
        images: virawarnImages,
        showThumbnails: false
    })
  }

  function ShowDoordashImages() {
    new ImageViewer({
        images: doordashImages,
        showThumbnails: false
    })
  }

  function ShowOperationsImages() {
    new ImageViewer({
        images: operationsImages,
        showThumbnails: false
    })
  }

  function ShowUptimeImages() {
    new ImageViewer({
        images: uptimeImages,
        showThumbnails: false
    })
  }

    // Do this on screen load
  React.useEffect(() => {
    StartTextAnimation();
  }, [])

  async function StartTextAnimation() {
    const text = "A full stack web & mobile developer";
    let index = 0;
  
    const intervalId = setInterval(() => {
      setAnimatedText(text.substring(0, index + 1));
      index++;
  
      if (index === text.length) {
        clearInterval(intervalId); // Stop the interval when the animation is complete
      }
    }, 100);

    setTimeout(function() {
        // Code to be executed after the pause
        StartTextAnimation2();
    }, 5000);
  }

  async function StartTextAnimation2() {
    const text = "ReactJS and Vue.js web development";
    let index = 0;
  
    const intervalId = setInterval(() => {
      setAnimatedText(text.substring(0, index + 1));
      index++;
  
      if (index === text.length) {
        clearInterval(intervalId); // Stop the interval when the animation is complete
      }
    }, 100);

    setTimeout(function() {
        // Code to be executed after the pause
        StartTextAnimation3();
    }, 5000);
  }

  async function StartTextAnimation3() {
    const text = "Flutter mobile iOS & Android development";
    let index = 0;
  
    const intervalId = setInterval(() => {
      setAnimatedText(text.substring(0, index + 1));
      index++;
  
      if (index === text.length) {
        clearInterval(intervalId); // Stop the interval when the animation is complete
      }
    }, 100);

    setTimeout(function() {
        // Code to be executed after the pause
        StartTextAnimation4();
    }, 5000);
  }

  async function StartTextAnimation4() {
    const text = "Scroll down for more and a portfolio";
    let index = 0;
  
    const intervalId = setInterval(() => {
      setAnimatedText(text.substring(0, index + 1));
      index++;
  
      if (index === text.length) {
        clearInterval(intervalId); // Stop the interval when the animation is complete
      }
    }, 100);
  }

    return (
        <div className="App">
            <MyAppBar />
            <header className="App-header" id="header">
                <h1 className="white-text" style={{ zIndex: '2', marginTop: '70px' }}>Hello, I'm Kyle</h1>
                <h1 className="gradient-text2" style={{ zIndex: '2' }}>{animatedText}</h1>
                <div className="white-text shadow-text">
                    <KeyboardArrowDownIcon className="arrow" /><br />
                    <span style={{ fontSize: '10px' }}>SCROLL DOWN</span>
                </div>
            </header>

            <body>


                <ScrollAnimation animateIn='fadeIn'
                    animateOut='fadeOut'
                    initiallyVisible={false}
                    animateOnce={true}>
                    <div className="App-features" id="features">
                        <h2 className="gradient-text">SERVICES</h2>
                        <Grid container spacing={2}>

                            <Grid item sm={12} md={4}>
                                <div className="feature">

                                    <WebIcon fontSize="large" />

                                    <h3 className="purple-text">FRONTEND WEB DEVELOPMENT</h3>

                                    Frontend web development accomplished with ReactJS or Vue.js – using JavaScript or TypeScript
                                </div>
                            </Grid>

                            <Grid item sm={12} md={4}>
                                <div className="feature">

                                    <PhoneAndroidIcon fontSize="large" />

                                    <h3 className="purple-text">MOBILE APP DEVELOPMENT</h3>

                                    Mobile application development accomplished with Flutter – for Apple and Android                    </div>
                            </Grid>

                            <Grid item sm={12} md={4}>
                                <div className="feature">

                                    <CodeIcon fontSize="large" />

                                    <h3 className="purple-text">BACKEND DEVELOPMENT</h3>

                                    Backend application development accomplished using Python, Node, or Go                  </div>
                            </Grid>

                        </Grid>
                    </div>
                </ScrollAnimation>
                <div style={{ width: '100%', maxWidth: '1080px', margin: '0 auto', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />


                <div style={{margin: '0 auto'}}>
            
                <ScrollAnimation animateIn='fadeIn'
                        animateOut='fadeOut'
                        initiallyVisible={false}
                        animateOnce={true}>
                            <h2 className="gradient-text" id="portfolio">PORTFOLIO</h2>
                    <Grid container spacing={0}>

                        <Grid item xs={12} sm={6}>
                            <div className="portfolio-container" style={{ position: 'relative', paddingBottom: '0px', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#A7D397'}}>
                                <h3 className="white-text shadow-text">ViraWarn</h3>
                                <p className="white-text shadow-text">A Flutter app developed for iOS and Android using BLE (bluetooth)</p>
                                <img className="portfolio-img"  style={{cursor: 'pointer', maxWidth: '90%'}} onClick={() => ShowVirawarnImages()} src="images/virawarn_thumbnail.png" alt="An image from a mobile app developed in Google Flutter, the app is from the ViraWarn product."/>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="portfolio-container" style={{ position: 'relative', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#00A9FF'}}>
                                <h3 className="white-text shadow-text">Delivery Clone</h3>
                                <img className="portfolio-img" style={{cursor: 'pointer', maxWidth: '90%'}} onClick={() => ShowDoordashImages()} src="images/doordash_thumbnail.png" alt="An image from a mobile app developed in Google Flutter, it is a mock app made only for my portfolio to look like a meal delivery app."/>
                                <p className="white-text shadow-text">An example project developed for my Portfolio in Flutter for iOS and Android</p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0}>

                        <Grid item xs={12} sm={6}>
                            <div className="portfolio-container" style={{ position: 'relative', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#F8BDEB' }}>
                                <h3 className="white-text shadow-text">IoT Update Tool</h3>
                                <img className="portfolio-img"  style={{cursor: 'pointer', maxWidth: '90%'}} onClick={() => ShowOperationsImages()} src="images/operations_thumbnail.png" alt="An image from a web application developed in React or ReactJS, the app updates IoT devices in the field remotely from a web interface."/>
                                <p className="white-text shadow-text">A ReactJS web application which remotely updates IoT devices</p>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="portfolio-container" style={{ position: 'relative', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#F4E869' }}>
                                <h3 className="white-text shadow-text">Uptime Monitor</h3>
                                <img className="portfolio-img" style={{cursor: 'pointer', maxWidth: '90%'}} onClick={() => ShowUptimeImages()} src="images/uptime_thumbnail.png" alt="An image for my portfolio of a web application made in React or ReactJS that is made just as a mock app for my portfolio."/>
                                <p className="white-text shadow-text">An example project developed for my Portfolio in ReactJS</p>
                            </div>
                        </Grid>
                    </Grid>

                </ScrollAnimation>
                </div>
                <div style={{ width: '100%', maxWidth: '1080px', margin: '0 auto', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />


                <div className="App-about" id="about">

                    <ScrollAnimation animateIn='fadeIn'
                        animateOut='fadeOut'
                        initiallyVisible={false}
                        animateOnce={true}>

                        <div className="portfolio-item" style={{ marginTop: '75px' }}>

                            <div className="portfolio-bg" style={{ zIndex: '1' }} />

                            <Grid container spacing={2}>

                                <Grid item sm={12} md={6}>

                                    <div style={{ padding: '50px', textAlign: 'left' }}>

                                        <h2 className="gradient-text">ABOUT ME</h2>

                                        <h3>Location</h3>
                                        Southeast Michigan, USA

                                        <h3>Education</h3>
                                        Associate of Science (AS), Cybersecurity

                                        <h3>Skills</h3>
                                        HTML, CSS, JavaScript, TypeScript, JSX, TSX, Dart, Golang, Python, ReactJS, Vue.js, Flutter, Docker, MongoDB, MySQL, Git, AWS, Firebase, Node/Express, Quart/Flask, Django

                                        <h3>Occupation</h3>
                                        Application Developer

                                    </div>

                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <div className="portfolio-image">
                                        <img src="images/kyle.jpeg" alt="A photo of the lead developer at kyleb.app, Kyle Bessemer." style={{ width: '50%', borderRadius: '100%', padding: '50px' }} />
                                    </div>

                                </Grid>

                            </Grid>

                        </div>

                    </ScrollAnimation>

                    {/* <div style={{ width: '75%', maxWidth: '1080px', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} /> */}

                </div>
                <div style={{ width: '100%', maxWidth: '1080px', margin: '0 auto', marginTop: '75px', marginBottom: '75px', height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }} />

                <div className="App-about" id="faq">

                    <ScrollAnimation animateIn='fadeIn'
                        animateOut='fadeOut'
                        initiallyVisible={false}
                        animateOnce={true}>

                        <div className="portfolio-item" style={{ marginTop: '75px' }}>

                            <div className="portfolio-bg" style={{ zIndex: '1' }} />

                            <h2 className="gradient-text">FREQUENTLY ASKED QUESTIONS</h2>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">What is a full stack web & mobile developer?</h3>
                                <br />
                                <div className="faq-answer">A full stack web & mobile developer is a programmer who specializes in web applications, and mobile applications (or apps) for Apple and Android. Full stack means I can develop your application's frontend and backend.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">What is ReactJS?</h3>
                                <br />
                                <div className="faq-answer">ReactJS is a library made by Facebook for creating web sites and applications. ReactJS apps are usually coded with HTML, CSS, and JavaScript or TypeScript. Rich and interactive web applications can be developed faster because of the tools ReactJS comes with.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">What is Vue.js?</h3>
                                <br />
                                <div className="faq-answer">Vue.js is a framework developed to create web applications. With its robust set of tools and various plugins, you can speed up development and create one of a kind experiences for your users. The languages used are HTML, CSS, and JavaScript or TypeScript.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">What is Flutter?</h3>
                                <br />
                                <div className="faq-answer">Flutter is a framework developed by Google for creating applications for mobile, web, or even desktop. I use Flutter for Apple and Android development, which speeds up development because the same code can be used for both phone types. There are endless possibilities for Flutter apps, including paid subscriptions, and bluetooth connections.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">What are your qualifications?</h3>
                                <br />
                                <div className="faq-answer">I currently hold an associates degree in Cybersecurity, where I learned about networking, operating systems, security, and programming. I have also completed several online bootcamps on the topics: Web development, Flutter development, Golang Development, and Adobe Illustrator. I also have over 5 years of experience in web development.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">How can I hire you?</h3>
                                <br />
                                <div className="faq-answer">I am available for part-time contracts only. I accept work through UpWork, which is a freelancer website. You can go to my profile by clicking <a href="https://www.upwork.com/freelancers/~014216a82a164c76a0" target="_blank">here</a>.</div>
                            </div>

                            <div className="faq-item" style={{ width: '90%', margin: '0 auto' }}>
                                <h3 className="faq-question">How can I contact you?</h3>
                                <br />
                                <div className="faq-answer">I can be contacted through UpWork, click <a href="https://www.upwork.com/freelancers/~014216a82a164c76a0" target="_blank">here</a>. I can also be contacted in my public Discord server, click <a href="https://discord.gg/7EtkKabkhc" target="_blank">here</a> to join.</div>
                            </div>

                        </div>

                    </ScrollAnimation>

                </div>

                <header className="App-header" id="header" style={{ marginTop: '30px' }}>
                    <h2>SITEMAP</h2>
                    <div style={{ width: '70%', margin: '0 auto' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div style={{ width: '100%', margin: '0 auto' }}>
                                    <div className="sitemap-item"><h3>Internal Links</h3></div><br />
                                    <div className="sitemap-item"><a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("features")}>Services</a></div><br />
                                    <div className="sitemap-item"><a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("portfolio")}>Portfolio</a></div><br />
                                    <div className="sitemap-item"><a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("about")}>About</a></div><br />
                                    <div className="sitemap-item"><a style={{ cursor: 'pointer' }} onClick={() => ScrollTo("faq")}>FAQ</a></div><br />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ width: '100%', margin: '0 auto' }}>
                                    <div className="sitemap-item"><h3>External Links</h3></div><br />
                                    <div className="sitemap-item"><a href="https://www.upwork.com/freelancers/~014216a82a164c76a0" target="_blank" style={{ cursor: 'pointer' }} className="sitemap-item">UpWork</a></div><br />
                                    <div className="sitemap-item"><a href="https://discord.gg/7EtkKabkhc" target="_blank" style={{ cursor: 'pointer' }} className="sitemap-item">Discord</a></div><br />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </header>
            </body>
        </div>
    );
}

export default HomePage;
